import React from "react"
import MeriIllust from "../../svg/MeritIllust"

import * as Styled from "./styles"

const flow = [
  {
    title: "申込書類のご提出",
    steps: [
      { __html: "CNW認証 取得申込書" },
      { __html: "データ取扱同意書" },
      { __html: "機密保持契約書" },
    ],
  },
  {
    title: "CO2排出量の算定",
    steps: [
      { __html: "認証取得希望ドメインのGoogle Analytics連携" },
      { __html: "カーボンアカウンティング" },
    ],
  },
  {
    title: "カーボンオフセット",
    steps: [
      {
        __html:
          "WEBサイト改善コンサルティング <br /><small>※こちらは、必須ではありません</small>",
      },
      { __html: "オフセット費用を含めたお見積もり / お支払い" },
    ],
  },
  {
    title: "CNW認証の発行",
    steps: [
      { __html: "CNW認証マークの使用契約書の締結" },
      { __html: "CNW認証の発行および使用マークの提供" },
    ],
  },
]

export default function Flow() {
  return (
    <Styled.Section id="flow">
      <Styled.Container>
        <Styled.SectionHeader>
          <Styled.SectionSubTitle>Flow</Styled.SectionSubTitle>
          <Styled.SectionTitle>認証マーク取得までの流れ</Styled.SectionTitle>
        </Styled.SectionHeader>
        {flow.map((step, i) => (
          <Styled.Step>
            <Styled.StepNumber>{i + 1}.</Styled.StepNumber>
            <Styled.StepTitle>{step.title}</Styled.StepTitle>
            <Styled.Steps>
              {step.steps.map((el) => (
                <li dangerouslySetInnerHTML={el} />
              ))}
            </Styled.Steps>
          </Styled.Step>
        ))}
      </Styled.Container>
      <Styled.SectionBottom>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
        >
          <path d="M0,192L60,202.7C120,213,240,235,360,234.7C480,235,600,213,720,197.3C840,181,960,171,1080,192C1200,213,1320,267,1380,293.3L1440,320L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
        </svg>
      </Styled.SectionBottom>
    </Styled.Section>
  )
}

import React, { useState } from "react"

import * as Styled from "./styles"

const prices = [
  {
    title: "法人・団体様",
    slug: "company",
    price: [
      {
        range: { __html: "10万未満" },
        first: "30000",
        mark1: "100000",
        mark2: "60000",
      },
      {
        range: { __html: "10万以上" },
        first: "120000",
        mark1: "120000",
        mark2: "80000",
      },
      {
        range: { __html: "100万以上" },
        first: "150000",
        mark1: "150000",
        mark2: "100000",
      },
      {
        range: { __html: "500万以上" },
        first: "180000",
        mark1: "180000",
        mark2: "120000",
      },
      {
        range: { __html: "1000万以上" },
        first: "240000",
        mark1: "250000",
        mark2: "180000",
      },
      {
        range: { __html: "1億以上" },
        first: "360000",
        mark1: "450000",
        mark2: "320000",
      },
    ],
  },
  {
    title: "個人様",
    slug: "person",
    price: [{ first: "5000", mark1: "6000", mark2: "" }],
  },
]

export default function PricingTable() {
  const [viewing, setViewing] = useState("company")
  const [viewingPV, setViewingPV] = useState(0)

  const price = (price) =>
    new Intl.NumberFormat("ja-JP", {
      style: "currency",
      currency: "JPY",
    }).format(price)

  return (
    <Styled.PricingTable>
      <Styled.Nothing></Styled.Nothing>
      {prices.map((p) => (
        <Styled.PricingHead
          active={viewing == p.slug}
          onClick={() => setViewing(p.slug)}
        >
          {p.title}
        </Styled.PricingHead>
      ))}

      <Styled.PricingDivder>認証マーク</Styled.PricingDivder>
      {prices.map((p) =>
        p.slug == "company" ? (
          <Styled.PricingDivderPH
            className={"company" + (viewing === "company" ? "" : " hide")}
          >
            <div className="text">
              認証マークの料金は、 PV数によって変動します。
              <br />
              認証取得希望サイトの年間PV数をお選びください。
            </div>
            {p.price.map((pd, i) => (
              <span
                dangerouslySetInnerHTML={pd.range}
                key={i}
                className={i === viewingPV ? "active" : ""}
                onClick={() => {
                  setViewingPV(i)
                }}
              ></span>
            ))}
          </Styled.PricingDivderPH>
        ) : (
          <Styled.PricingDivderPH />
        )
      )}

      <Styled.PricingRowHead>
        初回CO₂排出量計測
        <small>一回</small>
      </Styled.PricingRowHead>

      {prices.map((p) => (
        <Styled.PricingRowDetail active={viewing == p.slug}>
          {price(p.price[p.slug === "company" ? viewingPV : 0].first)}
        </Styled.PricingRowDetail>
      ))}

      <Styled.PricingRowHead>初回登録料</Styled.PricingRowHead>
      {prices.map((p) => (
        <Styled.PricingRowDetail active={viewing == p.slug}>
          {price(20000)}
        </Styled.PricingRowDetail>
      ))}

      <Styled.PricingRowHead>
        年間利用料
        <small>CO2排出調査・算定費用含む</small>
      </Styled.PricingRowHead>
      {prices.map((p) => (
        <Styled.PricingRowDetail active={viewing == p.slug}>
          {/* {price(p.price[p.slug === "company" ? viewingPV : 0].mark1)} */}
          <div>
            {p.slug === "company" ? <Styled.Tag>初年度</Styled.Tag> : ""}
            <span>
              {price(p.price[p.slug === "company" ? viewingPV : 0].mark1)}
              <small>/年</small>
            </span>
            {p.slug === "person" ? <small>{price(500)}/月</small> : ""}
          </div>
          {p.slug === "company" ? (
            <div>
              <Styled.Tag>2年目以降</Styled.Tag>
              <span>
                {price(p.price[p.slug === "company" ? viewingPV : 0].mark2)}
                <small>/年</small>
              </span>
            </div>
          ) : (
            ""
          )}
        </Styled.PricingRowDetail>
      ))}

      <Styled.PricingRowHead>
        カーボン・オフセット
        <small>当協会でオフセットする場合</small>
      </Styled.PricingRowHead>
      <Styled.PricingRowDetail active={viewing == "company"}>
        <small>計測後お見積り</small>
      </Styled.PricingRowDetail>
      <Styled.PricingRowDetail active={viewing == "person"}>
        <small>計測後お見積り</small>
      </Styled.PricingRowDetail>

      <Styled.PricingRowHead>年間契約更新手数料</Styled.PricingRowHead>
      <Styled.PricingRowDetail active={viewing == "company"}>
        {price(20000)}
      </Styled.PricingRowDetail>
      <Styled.PricingRowDetail active={viewing == "person"}>
        {price(5000)}
      </Styled.PricingRowDetail>

      <Styled.PricingDivder>
        ウェブサイト改善
        <br />
        コンサルティング
      </Styled.PricingDivder>
      {prices.map(() => (
        <Styled.PricingDivderPH />
      ))}

      <Styled.PricingRowHead>一回</Styled.PricingRowHead>
      <Styled.PricingRowDetail active={viewing == "company"}>
        {price(200000)}~
      </Styled.PricingRowDetail>
      <Styled.PricingRowDetail active={viewing == "person"}>
        ー
      </Styled.PricingRowDetail>
    </Styled.PricingTable>
  )
}

import React from "react"
import Badge from "./Badge"

import * as Styled from "./styles"

export default function Browser() {
  return (
    <>
      <Styled.Browser>
        <Styled.Top>
          <Styled.Button />
          <Styled.Button />
          <Styled.Button />

          <Styled.Bar>https://www.carbonneutral-web.org</Styled.Bar>
        </Styled.Top>
        <Styled.Content>
          <div>
            <Badge />
          </div>
          <div>
            <Badge white />
          </div>
        </Styled.Content>
      </Styled.Browser>
    </>
  )
}

import React from "react"
import Result from "../Result"

import * as Styled from "./styles"

export default function ResultModal({ closeModal, results, error }: any) {
  return (
    <>
      <Styled.Modal>
        <Styled.ModalInner>
          <Styled.ModalContent>
            {Object.keys(results.websiteData).length ? (
              <Result results={results} />
            ) : (
              <Styled.Loading>
                {error ? (
                  <>
                    <Styled.BigText>
                      「{results.url}」の解析中にエラーが発生しました。
                    </Styled.BigText>
                    <Styled.Error>
                      <p>エラーの原因として次が考えられます。</p>
                      <br />
                      <Styled.SmallText>
                        ①　ご指定のURLに誤りがあり、またはサイトへのアクセスが出来ない（ログインが必要、など）場合がございます。
                        <br />
                        ご確認の上、再度お試しください。
                      </Styled.SmallText>
                      <Styled.SmallText>
                        ②　アクセスの集中などにより、解析サービスが一時的接続できない状態になることがあります。
                        <br />
                        この場合は時間をおいて再度お試しください。
                      </Styled.SmallText>
                      <Styled.SmallText>
                        ③　ご指定のサイトの解析に時間がかかり、処理がタイムアウトする場合がございます。
                      </Styled.SmallText>
                      <Styled.SmallText>
                        解消されない場合は
                        <a href="#offset" onClick={() => closeModal()}>
                          こちらから
                        </a>
                        お問い合わせください。
                      </Styled.SmallText>
                    </Styled.Error>
                  </>
                ) : (
                  <>
                    <Styled.BigText>
                      100万回使われるファイルを1KBでも減らすことができると、
                      月間約2950gのCO₂削減になります。
                    </Styled.BigText>

                    <Styled.LoadingIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    </Styled.LoadingIcon>
                    <Styled.SmallText>
                      「{results.url}」のカーボンエミッションを計算中
                    </Styled.SmallText>
                  </>
                )}
                <Styled.Links>
                  <Styled.Button
                    href="#"
                    className="gray"
                    onClick={() => closeModal()}
                  >
                    とじる{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentcolor"
                        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                      />
                    </svg>
                  </Styled.Button>
                </Styled.Links>
              </Styled.Loading>
            )}
            <Styled.Links>
              {Object.keys(results.websiteData).length ? (
                <>
                  <Styled.Button
                    className="gray"
                    href="#problems"
                    onClick={() => closeModal()}
                  >
                    ウェブの環境問題について
                  </Styled.Button>
                  <Styled.Button
                    className="white"
                    href="#offset"
                    onClick={() => closeModal()}
                  >
                    CO₂をオフセットする
                  </Styled.Button>
                </>
              ) : null}
            </Styled.Links>
          </Styled.ModalContent>
        </Styled.ModalInner>
      </Styled.Modal>
      <Styled.Overlay onClick={() => closeModal()} />
    </>
  )
}

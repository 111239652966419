import tw, { styled } from "twin.macro"

import * as Common from "../../common/styles"

export const Section = styled(Common.Section)`
  ${tw`pt-20 pb-36 bg-primary`};
`

export const SectionBottom = styled.div`
  ${tw`top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden`}
  height:10vh;
  svg {
    ${tw`absolute bottom-0 overflow-hidden h-full w-full`}
    path {
      ${tw`fill-current text-white`}
    }
  }
`
export const Container = Common.Container
export const SectionSubTitle = styled(Common.SectionSubTitle)`
  ${tw`text-tertiary`}
`
export const SectionTitle = styled(Common.SectionTitle)`
  ${tw`text-white mb-10`}
`
export const SectionHeader = Common.SectionHeader
export const SectionText = Common.SectionText

export const Step = styled.div`
  ${tw`w-full text-white border-2 border-white px-5 py-4 rounded-xl max-w-3xl mx-auto mt-5 flex flex-col md:flex-row justify-between text-center md:text-left md:max-w-none`}

  max-width: 24rem;
`
export const StepNumber = tw.span`text-xl md:w-12`
export const StepTitle = tw.span`md:mr-auto text-lg mb-3 md:mb-0`
export const Steps = tw.ul`md:w-1/2 list-disc list-inside text-left flex flex-col gap-1`

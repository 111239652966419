import axios, { AxiosRequestConfig } from "axios"
import React from "react"

import * as Styled from "./styles"

interface Props {}

type MyState = {
  feedbackMsg: string | null
  feedbackError: boolean
}
class Contact extends React.Component<{}, MyState> {
  private domRef: React.RefObject<HTMLFormElement>

  constructor(props: Props) {
    super(props)
    this.domRef = React.createRef()
    this.state = { feedbackMsg: null, feedbackError: false }
  }

  handleSubmit(e) {
    e.preventDefault()
    const formData = new FormData(this.domRef.current || undefined) as any

    const axiosOptions = {
      url: window.location.pathname,
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: new URLSearchParams(formData).toString(),
    } as AxiosRequestConfig

    axios(axiosOptions)
      .then(() => {
        this.setState({
          feedbackMsg: "送信完了",
        })
        this.domRef.current?.reset()
      })
      .catch(() =>
        this.setState({
          feedbackMsg: "送信エラー",
          feedbackError: true,
        })
      )
  }

  render() {
    return (
      <Styled.FormWrap>
        <form
          action="#"
          method="POST"
          ref={this.domRef}
          onSubmit={(e) => this.handleSubmit(e)}
          name="contact_me"
          data-netlify="true"
          // data-netlify-recaptcha="true"
        >
          <input type="hidden" name="form-name" value="contact_me" />

          <Styled.FormGrid>
            <Styled.WrapHalf>
              <Styled.Label htmlFor="company">会社名 / 組織名</Styled.Label>
              <Styled.Required>※必須</Styled.Required>
              <Styled.Input type="text" name="company" id="company" required />
              <Styled.Info>
                ※ 個人でのお問い合わせの場合は、特になしと記載ください。
              </Styled.Info>
            </Styled.WrapHalf>

            <Styled.WrapHalf>
              <Styled.Label htmlFor="department">部署名</Styled.Label>
              <Styled.Input type="text" name="department" id="department" />
            </Styled.WrapHalf>

            <Styled.WrapHalf>
              <Styled.Label htmlFor="name">担当者名</Styled.Label>
              <Styled.Required>※必須</Styled.Required>
              <Styled.Input
                type="text"
                name="name"
                id="name"
                autoComplete="name"
                required
              />
            </Styled.WrapHalf>

            <Styled.WrapHalf>
              <Styled.Label htmlFor="tel">電話番号</Styled.Label>
              <Styled.Input type="tel" name="tel" id="tel" autoComplete="tel" />
            </Styled.WrapHalf>

            <Styled.WrapTwoThird>
              <Styled.Label htmlFor="email_address">
                メールアドレス
              </Styled.Label>
              <Styled.Required>※必須</Styled.Required>
              <Styled.Input
                type="text"
                name="email_address"
                id="email_address"
                autoComplete="email"
                required
              />
            </Styled.WrapTwoThird>

            <Styled.WrapTwoThird>
              <Styled.Label htmlFor="website">対象サイトのHP</Styled.Label>
              <Styled.Input type="url" name="website" id="website" />
            </Styled.WrapTwoThird>

            <Styled.WrapFull>
              <Styled.Label htmlFor="message">お問い合わせ内容</Styled.Label>
              <Styled.Textarea
                id="message"
                name="message"
                rows={4}
                placeholder=""
                defaultValue={""}
              />
            </Styled.WrapFull>
          </Styled.FormGrid>

          <Styled.Send type="submit">送信</Styled.Send>
        </form>

        {this.state.feedbackMsg && (
          <Styled.Notification error={this.state.feedbackError}>
            <div className="message">
              <p>{this.state.feedbackMsg}</p>
            </div>
          </Styled.Notification>
        )}
      </Styled.FormWrap>
    )
  }
}

export default Contact

import tw, { styled } from "twin.macro"

export const PricingTable = styled.div`
  ${tw`grid grid-cols-2 md:grid-cols-3 gap-x-2 mt-10 md:mt-14`}
`
export const Nothing = styled.div`
  ${tw`hidden md:block order-first`}
`

export const PricingHead = styled.div`
  ${tw`text-center md:text-lg bg-white py-2 md:py-4 font-bold text-primary rounded-xl md:rounded-b-none border shadow-sm md:shadow order-first`}

  ${({ active }) =>
    active && tw`bg-primary md:bg-white text-white md:text-primary shadow`}
`

export const PricingDivder = styled.h3`
  ${tw`col-span-full md:col-span-1 text-left font-bold pb-1 pt-4 md:pt-8 text-secondary self-end`}
`

export const PricingDivderPH = styled.div`
  ${tw`col-span-full md:col-span-1 hidden md:grid md:bg-white md:bg-opacity-50 md:border-l md:border-r md:border-gray-200 mt-2 md:mt-0`}

  &.company {
    ${tw`grid grid-cols-3 gap-1 text-xs order-first md:order-none md:p-2`}

    &.hide {
      ${tw`hidden md:grid`}
    }

    .text {
      ${tw`col-span-full text-gray-500 mt-2 md:mt-0`}
    }
    span {
      ${tw`flex items-center justify-center p-2 bg-white md:bg-gray-200 rounded hover:shadow-lg z-10 hover:bg-tertiary cursor-pointer transition shadow-sm`}

      &.active {
        ${tw`bg-tertiary shadow`}
      }
    }
  }

  & + h4 {
    ${tw`md:border-t`}
  }
`

export const PricingRowHead = styled.h4`
  ${tw`col-span-full md:col-span-1 text-left flex flex-col justify-center border-gray-300 border-dashed md:border-b px-4 md:px-6 py-2 bg-primary bg-opacity-5 md:bg-white md:bg-opacity-50`}

  small {
    ${tw`text-xs opacity-50`}
  }
`

export const PricingRowDetail = styled.div`
  ${tw`bg-white px-4 md:text-center py-4 md:border-b border-gray-200 md:shadow flex flex-col md:justify-center md:items-center gap-5 text-lg`}

  &>* {
    ${tw`flex flex-col items-start md:items-center md:justify-center`}
  }

  small {
    ${tw`text-xs opacity-50`}
  }

  ${({ active }) => !active && tw`hidden md:flex`}
  ${({ active }) => active && tw`col-span-full md:col-span-1`}
`

export const Tag = styled.span`
  ${tw`bg-primary px-3 py-1 rounded-xl text-white`}
  font-size: 10px;
  line-height: 1;
`

import tw, { styled } from "twin.macro"

export const BigText = tw.p`font-black text-xl md:text-3xl mb-8 leading-relaxed	`
export const SmallText = tw.p`text-sm mb-8`

export const Overlay = styled.div`
  ${tw`opacity-25 fixed inset-0 z-40 bg-primary`}
`

export const Modal = styled.div`
  ${tw`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-1/2	rounded-xl z-50 outline-none focus:outline-none w-11/12 md:w-2/3 h-3/4 md:h-2/3 scrollbar scrollbar-thumb-gray-900 scrollbar-track-gray-100 bg-primary shadow-xl`}
  transform:translate(-50%, -50%)
`

export const ModalInner = styled.div`
  ${tw`relative w-full h-full`}
`

export const ModalContent = styled.div`
  ${tw`border-8 border-primary relative w-full outline-none focus:outline-none p-6 h-full text-white`}
`

export const Loading = styled.div`
  ${tw`flex flex-col items-center text-center justify-center h-full overflow-y-auto`}
`
export const LoadingIcon = styled.div`
  ${tw`px-4 py-2 transition ease-in-out duration-150`}

  svg {
    ${tw`animate-spin -ml-1 mr-3 h-12 w-12 text-white mb-4`}
  }

  // TODO find out why not added
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  circle {
    ${tw`opacity-25`}
  }

  path {
    ${tw`opacity-75`}
  }
`

export const Links = styled.div`
  ${tw`flex items-center text-center justify-center flex-col-reverse sm:flex-row mb-4`}
`

export const Button = styled.a`
  ${tw`uppercase border-2 border-primary rounded-lg px-6 py-3 sm:py-2 text-primary cursor-pointer hover:bg-primary hover:text-white transition font-black mx-2 text-sm md:text-base mb-4 w-full sm:w-auto text-center inline-flex justify-center`}
  &.white {
    ${tw`bg-white text-primary border-white hover:bg-primary hover:text-white`}
  }
  &.gray {
    ${tw`bg-primary text-white text-opacity-75 border-secondary hover:bg-secondary hover:text-white`}
  }
`

export const Error = styled.div`
  ${tw`text-left`}
  a {
    ${tw`text-tertiary`}
  }
`

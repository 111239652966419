import tw, { styled } from "twin.macro"

import * as Common from "../../common/styles"

export const Section = styled(Common.Section)`
  ${tw`pt-20 pb-36 bg-tertiary bg-opacity-20`};
`

export const SectionBottom = styled.div`
  ${tw`top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden`}
  height:10vh;
  svg {
    ${tw`absolute bottom-0 overflow-hidden h-full w-full`}
    path {
      ${tw`fill-current text-primary`}
    }
  }
`
export const Container = Common.Container
export const SectionSubTitle = Common.SectionSubTitle
export const SectionTitle = Common.SectionTitle
export const SectionHeader = tw(Common.SectionHeader)`mb-12 md:mb-16`
export const SectionText = tw(Common.SectionText)`text-sm`
export const Wrapper = tw(
  Common.FlexWrapper
)`flex-col-reverse md:flex-row items-center`
export const Half = tw.div`w-full md:w-1/2`

export const Box = styled.div`
  ${tw`rounded-xl drop-shadow px-6 md:px-10 py-6 md:py-8 max-w-4xl mx-auto mt-16 bg-white  leading-relaxed`}
  p:not(last-of-type) {
    ${tw`mb-2`}
  }
`

export const Standards = tw.div`flex flex-col md:flex-row items-center justify-between gap-2 md:gap-4 mb-5 text-xl`
export const Standard = tw.div`text-center bg-primary text-white w-full md:w-1/3 flex items-center justify-center rounded-lg py-2 md:py-4`
export const BoxTitle = tw.h3`font-black text-2xl text-center mb-4 text-secondary `
export const CertificateTitle = tw.h3`mb-4 text-xl font-black text-primary`

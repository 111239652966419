import tw, { styled } from "twin.macro"

export const FormWrap = tw.div`mx-auto max-w-4xl mt-5 md:mt-0 md:col-span-2 md:px-12 md:py-10 md:bg-white md:bg-opacity-10 md:rounded-lg md:shadow-lg`
export const FormGrid = tw.div`grid grid-cols-6 gap-x-6 gap-y-4`
export const WrapHalf = tw.div`col-span-6 sm:col-span-3`
export const WrapTwoThird = tw.div`col-span-6 sm:col-span-4`
export const WrapFull = tw.div`col-span-6`
export const InputHalf = tw.div`col-span-6 sm:col-span-3`
export const Label = tw.label`text-sm font-medium text-gray-100 inline-block`
export const Required = tw.span`text-xs text-tertiary inline-block ml-1`
export const Info = tw.label`mt-2 text-xs text-white text-opacity-75`
export const Input = tw.input`mt-1 focus:ring-secondary focus:border-secondary block w-full shadow-sm sm:text-sm  rounded-md py-2 px-3 bg-gray-300 focus:bg-gray-100`
export const Textarea = tw.textarea`shadow-sm focus:ring-secondary focus:border-secondary mt-1 block w-full sm:text-sm rounded-md py-2 px-3 bg-gray-300 focus:bg-gray-100`
export const Send = tw.button`mt-8 mx-auto table py-2 px-12 border border-transparent shadow-sm text-sm font-black rounded-md text-primary bg-gray-300 hover:bg-primary hover:text-gray-300 transition focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary `

export const Notification = styled.div`
  ${tw`shadow rounded border-2 text-center text-green-500 border-gray-300 mx-auto mb-8 p-4 mt-12`}
  ${(props: any) => props.error && tw`text-red-600`}

  .message {
    ${tw`text-sm`}
  }
`

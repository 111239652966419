import tw, { styled } from "twin.macro"

export const ResultWeight = styled.p`
  ${tw`text-xl md:text-7xl font-black leading-relaxed`}
  span {
    ${tw`text-sm md:text-2xl`}
  }
`
export const ResultWrap = tw.div`flex flex-col items-center justify-center text-sm mb-6`
export const BigText = styled.div`
  ${tw`font-black text-xl md:text-4xl mb-2 md:mb-4 leading-relaxed text-center`}
  span {
    ${tw`block md:text-2xl`}
  }
`
export const Result = tw.div`text-center flex flex-col items-center justify-center bg-tertiary rounded-xl md:rounded-full p-4 md:h-72 w-3/4 md:w-72 md:mr-16 mb-8 md:mb-0 text-xs md:text-base`

export const Wrapper = tw.div`flex flex-col md:flex-row mt-8 mb-8 items-center`
export const Examples = tw.div`flex flex-col font-black`
export const ExamplesTitle = tw.div`text-xl md:text-3xl font-black mb-12`
export const ExampleWrap = tw.div`flex mb-4 items-center`
export const ExampleTitle = tw.div`md:text-xl leading-6 font-medium text-white`
export const ExampleDescription = tw.div`mt-2 text-sm md:text-base text-white text-opacity-50 font-normal`
export const ExampleIcon = tw.div`flex-shrink-0 flex items-center justify-center h-16 w-16 rounded-full border-2 border-white text-white p-4 mr-4`

import React from "react"

import * as Styled from "./styles"

export default function Result({ results }: any) {
  const { co2, kwh } = results.websiteData
  return (
    <>
      <Styled.ResultWrap>
        <Styled.BigText>
          <span>解析結果</span>「{results.url}」
        </Styled.BigText>
        <Styled.Wrapper>
          <Styled.Result>
            <Styled.ResultWeight>
              <span>約 </span>
              {co2.toFixed(2)}g
            </Styled.ResultWeight>
            <p>
              アクセスするたびに
              <br />
              排出されるCO₂の量
            </p>
          </Styled.Result>
          <Styled.Examples>
            <Styled.ExamplesTitle>
              月10万PVの場合、1年間で…
            </Styled.ExamplesTitle>
            <Styled.ExampleWrap>
              <Styled.ExampleIcon>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="smog"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                >
                  <path
                    fill="currentColor"
                    d="M624 368H80c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h544c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm-480 96H16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h128c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm416 0H224c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h336c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zM144 288h156.1c22.5 19.7 51.6 32 83.9 32s61.3-12.3 83.9-32H528c61.9 0 112-50.1 112-112S589.9 64 528 64c-18 0-34.7 4.6-49.7 12.1C454 31 406.8 0 352 0c-41 0-77.8 17.3-104 44.8C221.8 17.3 185 0 144 0 64.5 0 0 64.5 0 144s64.5 144 144 144z"
                  ></path>
                </svg>{" "}
              </Styled.ExampleIcon>
              <div>
                <Styled.ExampleTitle>
                  {(co2 * 120).toFixed(2)}kgのCO₂を排出
                </Styled.ExampleTitle>
                <Styled.ExampleDescription>
                  東京から沖縄まで{((co2 * 120) / 298).toFixed(2)}
                  回行けます。
                </Styled.ExampleDescription>
              </div>
            </Styled.ExampleWrap>
            <Styled.ExampleWrap>
              <Styled.ExampleIcon>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="tree"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                >
                  <path
                    fill="currentColor"
                    d="M378.31 378.49L298.42 288h30.63c9.01 0 16.98-5 20.78-13.06 3.8-8.04 2.55-17.26-3.28-24.05L268.42 160h28.89c9.1 0 17.3-5.35 20.86-13.61 3.52-8.13 1.86-17.59-4.24-24.08L203.66 4.83c-6.03-6.45-17.28-6.45-23.32 0L70.06 122.31c-6.1 6.49-7.75 15.95-4.24 24.08C69.38 154.65 77.59 160 86.69 160h28.89l-78.14 90.91c-5.81 6.78-7.06 15.99-3.27 24.04C37.97 283 45.93 288 54.95 288h30.63L5.69 378.49c-6 6.79-7.36 16.09-3.56 24.26 3.75 8.05 12 13.25 21.01 13.25H160v24.45l-30.29 48.4c-5.32 10.64 2.42 23.16 14.31 23.16h95.96c11.89 0 19.63-12.52 14.31-23.16L224 440.45V416h136.86c9.01 0 17.26-5.2 21.01-13.25 3.8-8.17 2.44-17.47-3.56-24.26z"
                  ></path>
                </svg>
              </Styled.ExampleIcon>
              <div>
                <Styled.ExampleTitle>
                  木が吸収するCO₂の量の{Math.ceil(co2 / 0.183)}倍
                </Styled.ExampleTitle>
                <Styled.ExampleDescription>
                  オフセットするのに、木が{Math.ceil(co2 / 0.183)}
                  つ必要、ということです。
                </Styled.ExampleDescription>
              </div>
            </Styled.ExampleWrap>
            <Styled.ExampleWrap>
              <Styled.ExampleIcon>
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="car"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M499.99 176h-59.87l-16.64-41.6C406.38 91.63 365.57 64 319.5 64h-127c-46.06 0-86.88 27.63-103.99 70.4L71.87 176H12.01C4.2 176-1.53 183.34.37 190.91l6 24C7.7 220.25 12.5 224 18.01 224h20.07C24.65 235.73 16 252.78 16 272v48c0 16.12 6.16 30.67 16 41.93V416c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-32h256v32c0 17.67 14.33 32 32 32h32c17.67 0 32-14.33 32-32v-54.07c9.84-11.25 16-25.8 16-41.93v-48c0-19.22-8.65-36.27-22.07-48H494c5.51 0 10.31-3.75 11.64-9.09l6-24c1.89-7.57-3.84-14.91-11.65-14.91zm-352.06-17.83c7.29-18.22 24.94-30.17 44.57-30.17h127c19.63 0 37.28 11.95 44.57 30.17L384 208H128l19.93-49.83zM96 319.8c-19.2 0-32-12.76-32-31.9S76.8 256 96 256s48 28.71 48 47.85-28.8 15.95-48 15.95zm320 0c-19.2 0-48 3.19-48-15.95S396.8 256 416 256s32 12.76 32 31.9-12.8 31.9-32 31.9z"
                  ></path>
                </svg>{" "}
              </Styled.ExampleIcon>
              <div>
                <Styled.ExampleTitle>…{Math.ceil(kwh)}KWh</Styled.ExampleTitle>
                <Styled.ExampleDescription>
                  電気自動車が{Math.ceil(kwh * 6.45)}
                  km走るのに使う電気と同じ量です。
                </Styled.ExampleDescription>
              </div>
            </Styled.ExampleWrap>
          </Styled.Examples>
        </Styled.Wrapper>
      </Styled.ResultWrap>
    </>
  )
}

import React from "react"
import Background from "../Background"
import CalculationInput from "../CalculationInput"

import * as Styled from "./styles"

function Header({ analyzeWebsite, reset }: any) {
  return (
    <Styled.Header id="calculator">
      <Background />

      <Styled.Overlay />
      <Styled.Hero>
        <Styled.HeroWrap>
          <Styled.Title>
            <span>Change your Website.</span>
            <span>Change your fans and the earth.</span>
          </Styled.Title>
          <Styled.SubTitle>
            ― 減量することはお客様と環境に優しい ―
          </Styled.SubTitle>
          <Styled.Text>
            ウェブでは、目に見えるゴミがなく、”エコ”
            だと思われがちですが、実は、大量の電力を消費しています。
          </Styled.Text>
          <Styled.Text>
            データセンターやネット回線、端末による電力消費によって、
            環境負荷となる二酸化炭素を排出しています。
          </Styled.Text>
          <Styled.Text>
            あなたのウェブサイトの環境負荷計測してみましょう。
          </Styled.Text>
          <CalculationInput analyzeWebsite={analyzeWebsite} reset={reset} />
        </Styled.HeroWrap>
      </Styled.Hero>
      <Styled.HeroBottom>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
        >
          <path d="M0,160L48,160C96,160,192,160,288,181.3C384,203,480,245,576,256C672,267,768,245,864,229.3C960,213,1056,203,1152,218.7C1248,235,1344,277,1392,298.7L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>
      </Styled.HeroBottom>
    </Styled.Header>
  )
}

export default Header

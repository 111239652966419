import React, { ReactElement } from "react"
import Header from "../components/Header"
import Problems from "../components/Problems"
import Solutions from "../components/Solutions"
import Certificate from "../components/Certificate"
import Offset from "../components/Offset"
import Merits from "../components/Merits"
import Media from "../components/Media"
import Flow from "../components/Flow"
import Pricing from "../components/Pricing"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar"
import ResultModal from "../components/ResultModal"
import Seo from "../components/SEO"

import { getWebsiteData } from "../utils"
import { styled } from "twin.macro"

const Main = styled.main`
  font-family: "Lato", "Noto Sans JP", "ヒラギノ角ゴ ProN",
    "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック",
    "MS PGothic", sans-serif;
`

function Index(): ReactElement {
  const [showModal, setShowModal] = React.useState(false)
  const [results, setResults] = React.useState({ url: "", websiteData: "" })
  const [error, setError] = React.useState(false)

  const analyzeWebsite = async (url: string) => {
    setShowModal(true)
    setResults({ ...results, url: url })

    const websiteData = await getWebsiteData(url)
    if (websiteData.error) {
      setError(true)
    } else {
      setResults({ url, websiteData })
    }
  }

  const closeModal = () => {
    if (error) {
      reset()
    }
    setShowModal(false)
  }

  const reset = () => {
    setResults({ url: "", websiteData: "" })
    setError(false)
  }

  return (
    <React.StrictMode>
      <Seo title="none" />
      <Navbar />
      <Main modalOpen={showModal}>
        <Header analyzeWebsite={analyzeWebsite} reset={reset} />
        <Problems />
        <Solutions />
        <Certificate />
        <Flow />
        <Merits />
        <Media />
        <Pricing />
        <Offset />
        {showModal ? (
          <ResultModal
            closeModal={closeModal}
            results={results}
            error={error}
          />
        ) : null}
      </Main>
      <Footer />
    </React.StrictMode>
  )
}

export default Index

import React from "react"
import PricingTable from "./PricingTable"

import * as Styled from "./styles"

export default function Pricing() {
  return (
    <Styled.Section id="price">
      <Styled.Container>
        <Styled.SectionHeader>
          <Styled.SectionSubTitle>Pricing</Styled.SectionSubTitle>
          <Styled.SectionTitle>料金</Styled.SectionTitle>
          <PricingTable />
        </Styled.SectionHeader>
      </Styled.Container>
      <Styled.SectionBottom>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
        >
          <path d="M0,192L60,202.7C120,213,240,235,360,234.7C480,235,600,213,720,197.3C840,181,960,171,1080,192C1200,213,1320,267,1380,293.3L1440,320L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
        </svg>
      </Styled.SectionBottom>
    </Styled.Section>
  )
}

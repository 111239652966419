import React, { useState } from "react"

import * as Styled from "./styles"

const CalculationInput = ({ analyzeWebsite, reset }: any) => {
  const [protocol, setProtocol] = useState("https")
  const [host, setHost] = useState("")

  const url = protocol + "://" + host

  return (
    <Styled.Wrapper>
      <Styled.InputGroup>
        <Styled.Prefix>
          <label htmlFor="currency">Protocol</label>
          <Styled.Select
            id="currency"
            name="currency"
            value={protocol}
            onChange={(e: any) => setProtocol(e.target.value)}
          >
            <option value="https">https://</option>
            <option value="http">http://</option>
          </Styled.Select>
        </Styled.Prefix>
        <Styled.Input
          type="text"
          name="company_website"
          id="company_website"
          onChange={(e) => {
            reset()
            setHost(e.target.value)
          }}
          placeholder="www.example.com"
          required
        />
        <Styled.Button
          onClick={() => analyzeWebsite(url)}
          disabled={host === ""}
        >
          CO₂排出を計算
        </Styled.Button>
      </Styled.InputGroup>
    </Styled.Wrapper>
  )
}

export default CalculationInput

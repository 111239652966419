import React from "react"
import Problems01 from "../../svg/Problems01"
import Problems02 from "../../svg/Problems02"
import Problems03 from "../../svg/Problems03"

import * as Styled from "./styles"

const problems = [
  {
    illust: Problems01,
    name: {
      __html: "ICTテクノロジーは現在、<wbr>年間1,500TWhを<wbr>消費しています。",
    },
    description: {
      __html:
        "それは世界の<wbr>電力消費量の<wbr>約10％であり、<wbr>日本とドイツのすべての<wbr>発電量を合わせたものに<wbr>等しい。",
    },
    source:
      "https://www.tech-pundit.com/wp-content/uploads/2013/07/Cloud_Begins_With_Coal.pdf?c761ac",
  },
  {
    illust: Problems02,
    name: {
      __html:
        "インターネット産業を<wbr>一つの<wbr>国とすると、<wbr>ロシア、<wbr>日本、<wbr>中国、<wbr>インド、<wbr>米国に次ぐ<wbr>地球上で<wbr>6番目に<wbr>大きな<wbr>電力消費者に<wbr>なります。",
    },
    description: {
      __html:
        "技術が発展しても、<wbr>ウェブの需要成長には<wbr>追いつきません。",
    },
    source:
      "https://greenpeace.org/usa/wp-content/uploads/legacy/Global/usa/planet3/PDFs/clickingclean.pdf",
  },
  {
    illust: Problems03,
    name: {
      __html:
        "インターネットは、<wbr>世界のCO2排出量の<wbr>約2％を毎年生み出しています。",
    },
    description: { __html: "これは、航空業界と同じ量です。" },
    source:
      "https://phys.org/news/2013-01-greenhouse-gas-emissions-internet-telecommunications.html#jCp",
  },
]

export default function Problems() {
  return (
    <Styled.Section id="problems">
      <Styled.Container>
        <Styled.SectionHeader>
          <Styled.SectionSubTitle>Problems</Styled.SectionSubTitle>
          <Styled.SectionTitle>ウェブによる環境問題</Styled.SectionTitle>
          <Styled.SectionText>
            インターネットは目には見えませんが、膨大な量のエネルギーを使用し、
            今や主要な炭素排出源となっています。<br></br>
            インターネットの利用は世界的にも年々増加しており、だからこそ一人一
            人が責任を持って排出を抑制するための行動を起こす必要があります。
          </Styled.SectionText>
        </Styled.SectionHeader>
        <Styled.FlexWrapper>
          {problems.map((problem, i) => (
            <Styled.ProblemCard key={i}>
              <Styled.ProblemCardWrap>
                <Styled.ProblemCardIcon>
                  {problem.illust && <problem.illust />}
                </Styled.ProblemCardIcon>
                <Styled.ProblemCardTitle
                  dangerouslySetInnerHTML={problem.name}
                ></Styled.ProblemCardTitle>
                <Styled.ProblemCardText
                  dangerouslySetInnerHTML={problem.description}
                ></Styled.ProblemCardText>
                <Styled.ProblemCardSource>
                  {problem.source && (
                    <a href={problem.source} target="_blank" rel="noreferrer">
                      参考文献
                    </a>
                  )}
                </Styled.ProblemCardSource>
              </Styled.ProblemCardWrap>
            </Styled.ProblemCard>
          ))}
        </Styled.FlexWrapper>
      </Styled.Container>
      <Styled.SectionBottom>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
        >
          <path d="M0,192L60,202.7C120,213,240,235,360,234.7C480,235,600,213,720,197.3C840,181,960,171,1080,192C1200,213,1320,267,1380,293.3L1440,320L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
        </svg>
      </Styled.SectionBottom>
    </Styled.Section>
  )
}

import React from "react"
import { styled } from "twin.macro"

const SVG = styled.svg`
  .st0 {
    fill: #ebf1df;
  }
  .st1 {
    fill: #e4e2ef;
  }
  .st2 {
    fill: #f9e0cf;
  }
  .st3 {
    fill: #24333f;
  }
  .st4 {
    fill: #eb9c24;
  }
  .st5 {
    fill: #de8223;
  }
  .st6 {
    fill: #a9bec4;
  }
  .st7 {
    fill: none;
    stroke: #24333f;
    stroke-width: 3;
    stroke-miterlimit: 10;
  }
  .st8 {
    fill: none;
    stroke: #24333f;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }
  .st9 {
    fill: #eed7c7;
  }
  .st10 {
    fill: none;
    stroke: #000000;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-miterlimit: 10;
  }
  .st11 {
    fill: #e2755b;
  }
  .st12 {
    fill: #e89d22;
  }
  .st13 {
    fill: #3d5ca1;
  }
`

function MeriIllust() {
  return (
    <SVG
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1714.73 1387.03"
    >
      <g>
        <path
          className="st0"
          d="M1693.33,702.98c2.79,93.08,9.03,301.64-116.86,388.19c-124.19,85.38-230.76-48.49-458.2-9.79
		c-276.81,47.1-310.61,278-575.22,292.42c-112.69,6.14-278.68-26.36-321.06-124.05c-52.42-120.8,126.56-243.64,70.68-396.82
		c-35.08-96.17-118.77-83.79-168.75-181.09C52.34,532.5,108.18,281.71,253.57,191.75c219.41-135.77,495.25,186.07,778.63,43.38
		c154.93-78.01,146.81-211.62,257.33-217.65C1474.57,7.39,1683.41,371.82,1693.33,702.98z"
        />
        <path
          className="st1"
          d="M296.16,932.93c-3.17-8.69-13.92-12.44-20.87-12.75c-14.14-0.63-28.72,12.14-35.82,32.07
		c-5.57,10.47-8.72,19.5-10.55,25.89c-2.01,7-2.84,12.24-3.09,13.91c-0.82,5.43-1.13,10.39-1.16,14.68
		c8.56-7.28,17.11-14.55,25.67-21.83c2.99-2.16,7.35-5.37,12.54-9.41c8.19-6.37,8.59-7.13,15.51-12.43
		c9.8-7.49,10.56-7.09,12.75-10.05C292.57,951.08,299.41,941.84,296.16,932.93z"
        />
        <path
          className="st1"
          d="M336.08,979.16c-2,7.36-3.85,16.86-4.22,28c-0.49,14.87,1.83,27.27,4.22,36.05
		c3.71-3.96,7.41-7.93,11.12-11.89c5.5-16.36,10.99-32.73,16.49-49.09C354.49,981.21,345.28,980.18,336.08,979.16z"
        />
        <path
          className="st2"
          d="M437.78,263.18c-4.69-4.69-0.33-10.01-4.58-19.56c-3.88-8.72-9.17-8.01-12.47-16.63
		c-2.51-6.55-3.03-16.32,1.56-19.22c5.06-3.2,14.1,3.36,16.63,5.2c8.7,6.31,12.11,14.97,14.55,21.3c3.64,9.45,9.24,24,2.63,29.93
		C451.62,268.22,442.26,267.66,437.78,263.18z"
        />
        <path
          className="st2"
          d="M61.72,763.31c-4.99-0.63-6.52,6.75-15.11,11.21c-8.99,4.66-13.6-0.15-21.94,3.41
		c-11.71,5-18.98,21.47-15.11,26.33c4.55,5.72,24.9-4.1,34.62-8.78c11.77-5.67,25.37-12.23,25.35-21.45
		C69.51,769.17,65.71,763.82,61.72,763.31z"
        />
        <path
          className="st2"
          d="M305.05,438.85c13.53-1.65,23.71,6.55,27.11,9.3c3,2.42,5.03,4.44,5.66,5.14c2.44,2.73,8.08,9.88,9.06,18.87
		c1.3,11.93-7.13,15.04-4.65,25.56c0.51,2.18,1.04,2.84,6.97,12.39c12.07,19.43,12.45,20.82,12.39,22.46
		c-0.44,13.19-28.45,27.18-37.96,20.14c-4.34-3.21-1.28-8.19-6.97-23.24c-2.7-7.14-5.36-13.93-11.62-19.37
		c-6.83-5.92-10.67-3.92-17.04-8.52c-9.11-6.59-14.93-20.58-12.39-34.08C278.36,452.85,290.62,440.61,305.05,438.85z"
        />
        <path
          className="st3"
          d="M260.93,437.52c0.8-1.34,9.3-15.26,20.41-14.84c7.67,0.29,10.7,7.18,16.7,5.57
		c5.55-1.49,4.75-7.88,11.13-11.13c6.09-3.11,16.57-2.27,21.65,4.95c3.83,5.45,3.76,13.04,0.62,17.94c-2.31,3.6-4.06,2.29-16.7,9.28
		c-9.63,5.32-10.27,7-15.46,8.04c-3.91,0.79-7.31,0.59-8.04,2.47c-0.97,2.49,4,5.25,3.09,8.04c-0.88,2.71-5.97,1.38-9.28,4.95
		c-2.84,3.07-3.62,8.95-1.24,11.75c3.49,4.1,11.74-0.78,14.84,3.09c2.21,2.76,0.83,8.75-2.47,11.75
		c-5.91,5.37-18.56,1.64-25.97-4.33c-8-6.44-9.92-15.5-11.75-24.12C256.57,462.05,253.89,449.38,260.93,437.52z"
        />
        <path
          className="st4"
          d="M429.38,739.28c11.01-3.13,25.63,12.32,54.86,43.22c16.82,17.78,27.36,32.07,34.91,42.39
		c19.46,26.59,25.91,41.05,29.09,49.04c6.48,16.27,11.49,29.41,5.82,41.56c-3.15,6.74-9.62,10.96-22.44,19.12
		c-18.26,11.62-27.39,17.43-38.23,18.29c-22.61,1.78-40.83-17.78-49.04-26.6c-29.64-31.84-32-72.76-33.25-101.4
		C410.58,813.12,407.64,745.46,429.38,739.28z"
        />
        <path
          className="st5"
          d="M316.09,802.75c-8.59,15-5.85,31.64-5.72,32.39c2.82,15.86,13.76,25.94,18.42,30.49
		c13.56,13.24,31.61,35.97,50.18,77.49c-0.83,2.7-2.42,6.19-5.72,8.26c-7.35,4.61-18.29-0.96-22.87-3.18
		c-12.8-6.21-31.87-13.42-62.24-21.59c-2.12,10.8-4.23,21.59-6.35,32.39c15.46,9.95,30.91,19.9,46.37,29.85
		c19.24,11.21,36.26,17.73,48.27,21.6c22.46,7.23,38.77,12.48,59.07,8.26c8.26-1.72,26.58-5.53,37.47-20.96
		c8.87-12.56,8.23-26.33,6.99-47c-0.56-9.23-2.25-25.52-11.43-53.99c-2.6-8.06-10.15-31.23-26.04-62.24
		c-15.88-30.98-23.82-46.47-34.3-52.72C379.65,764.78,331.95,775.03,316.09,802.75z"
        />
        <path
          className="st6"
          d="M310.17,528.77c7.92,9.5,20.09,13.1,29.32,9.26c10.7-4.45,16.51-18.52,15-24.69
		c-0.16-0.66-0.73-2.56,0.21-4.14c1.55-2.61,6.13-2.47,7.24-2.48c11.9-0.19,29.72-24.65,31.66-27.32
		c2.6-3.57,11.05-15.57,19.04-38.49c1.91-5.47,7.38-21.87,11.59-53.39c2.79-20.85,2.36-30.65,4.14-65.39
		c0.58-11.39,2.67-27.57,3.19-50.66c0.13-5.68,0.16-10.37,0.16-13.5c13.43-0.17,26.86-0.34,40.28-0.51
		c0.43,10.91,0.85,21.83,1.28,32.74c4.81,38.01,5.86,70.67,5.63,95.91c-0.23,24.35-0.82,66.7-11.04,119.32
		c-5.46,28.08-8.79,34.04-12.2,60.44c-1.41,10.86-4.71,39.77-2.91,78.46c3.13,66.97,18.23,91.47,0.58,120.88
		c-5.7,9.5-13.72,17.67-19.76,23.83c-12.45,12.69-26.35,26.85-47.07,34.29c-21.4,7.68-55.54,10.15-73.23-8.14
		c-0.64-0.66-2.56-3.96-4.76-8.33c-4.91-9.72-6.82-17.7-7.44-20.14c-3.46-13.67-15.25-45.62-36.03-70.9
		c-6.27-7.62-12.26-13.27-19.76-13.37c-8.91-0.11-15.45,7.67-19.18,11.62c-4.33,4.59-21.54,12.89-55.79,29.64
		c-27.96,13.67-31.6,13.38-62.18,27.9c-16.34,7.75-29.57,14.55-38.36,19.18c-5.42-10.27-10.85-20.53-16.27-30.8
		c11.43-9.3,22.86-18.6,34.29-27.9c31.98-23.79,54.75-46.38,69.74-62.77c28.86-31.55,32.23-43.78,65.67-76.13
		c18.99-18.38,37.64-33.52,58.12-47.07C292.72,538.57,302.74,532.81,310.17,528.77z"
        />
        <path
          className="st7"
          d="M1222.94,216.61c2.92-2.07,7.11-4.86,12.36-7.8c9.55-5.34,28.27-14.32,58.92-17.81
		c20.79-2.37,25.09,0.28,59.97,0.6c21.39,0.19,38.87-0.63,50.34-1.36"
        />
        <path
          className="st8"
          d="M1296.36,321.52c25.56,7.52,44.83,16.71,57.82,23.81c26.09,14.25,31.59,22.65,53.06,28.57
		c8.09,2.23,28.59,7.62,53.74,1.36c19.39-4.83,32.93-14.43,40.14-20.41"
        />
        <path
          className="st3"
          d="M1212.82,290.3c-3.1-5.23-9.27-15.77-9.97-30.68c-0.22-4.59-1.11-23.42,11.7-38.11
		c6.07-6.96,12.9-10.34,19.6-13.65c9.73-4.81,18.89-6.99,26.24-8.07c25.56-3.75,47.66,3.19,66.48,4.57
		c21.58,1.59,49.24-9.93,104.57-32.95c19.96-8.31,37.09-16.47,60.6-13.81c17.21,1.95,29.25,8.71,31.45,9.97
		c27.51,15.82,43.67,50.19,42.19,81.31c-2.36,49.58-49.02,78.22-69.03,90.51c-17.24,10.58-38.81,23.63-67.5,23.01
		c-54.25-1.17-77.06-50.16-126.35-41c-13.65,2.54-18.18,7.46-32.43,6.48C1246.68,326.26,1224.68,310.29,1212.82,290.3z"
        />
        <path
          className="st2"
          d="M1256.35,246.35c-18.83-1.08-35.64,21.46-35.93,39.19c-0.26,15.77,12.66,21.15,9.8,37.02
		c-1.05,5.83-5.78,12.6-15.24,26.13c-12.05,17.23-15.55,18.8-15.24,23.95c0.77,12.66,23.4,27.61,39.19,21.77
		c15.2-5.61,19.41-28.76,19.6-40.28c0.2-12.38-4.14-17.22,0-25.04c4.36-8.23,11.64-7.5,19.6-17.42c0.51-0.63,7.22-9.18,8.71-19.6
		C1289.47,273.59,1275.9,247.48,1256.35,246.35z"
        />
        <path
          className="st3"
          d="M1258.65,239.21c0.53,1.06-0.11,2.22-9.71,14.78c-9.24,12.09-10.39,13.3-12.25,14.78
		c-6.22,4.96-17.42,10.55-21.12,7.18c-4.36-3.98,1.04-21.06,12.67-30.41C1239.76,236.28,1256.64,235.21,1258.65,239.21z"
        />
        <path
          className="st3"
          d="M1253.58,232.03c-3.88,3.94-0.87,15.87,5.07,22.81c5.99,6.99,12.25,5.74,16.47,13.09
		c3.33,5.8,0.68,8.72,2.96,16.9c2.71,9.7,10.71,20.87,16.9,20.27c8.25-0.8,17.6-22.93,12.25-40.97
		C1299.32,237.48,1260.8,224.68,1253.58,232.03z"
        />
        <path
          className="st9"
          d="M1128.88,643.21c-7.45,5.72-10.92,25.22-17.84,64.21c-1.76,9.93-3.19,19.63,0,31.21
		c3.14,11.4,9.18,18.9,14.27,24.97c20.81,24.82,31.22,37.22,52.62,54.4c24.98,20.05,30.39,19.06,45.48,35.67
		c4.7,5.17,9.02,10.4,9.02,10.4c3.51,4.46,9.37,12.46,15.06,23.49c9.78,18.96,9.65,28.69,12.49,28.54
		c4.42-0.24,9.23-21.59,5.35-41.92c-1.1-5.74-3.3-13.34-10.7-26.76c-12.69-23-23.35-31.57-36.57-49.05
		c-14.46-19.12-23.96-31.69-27.65-48.16c-9.22-41.16,27.64-62.92,16.05-88.29C1195.67,638.29,1149.89,627.09,1128.88,643.21z"
        />
        <path
          className="st2"
          d="M1192.8,655.6c-6.88,7.49-8.4,23.63-11.44,55.93c-4.31,45.83,0.61,54.07,4.45,58.47
		c10.02,11.45,26.29,12.23,36.23,12.71c14.08,0.67,28.56-2.07,70.54-23.51c48.79-24.92,44.13-28.83,73.09-40.04
		c20-7.74,28.59-12.15,45.76-20.97c21.02-10.79,23.99-14.02,23.51-15.89c-1.15-4.49-20.34-4.81-36.86-1.27
		c-13.93,2.98-19.85,7.4-34.95,14.62c-17.4,8.32-37.23,13.6-76.9,24.15c-18.34,4.88-28.77,7.09-33.05,1.91
		c-2.21-2.68-2.62-7.14,1.27-19.07c9.81-30.1,23.97-37.24,21.61-45.76C1271.76,641.44,1215.74,630.64,1192.8,655.6z"
        />
        <path
          d="M1389.56,683.18c-1.05-2.08,2.62-6.63,6.79-9.06c4.55-2.64,7.5-1.35,16.98-1.6c11.86-0.32,17.89-2.03,30.44-0.64
		c4.42,0.49,6.31,0.99,6.73,2.24c1.29,3.87-12.27,11.52-15.7,13.46c-5.52,3.11-11.74,5.83-12.45,4.77
		c-0.79-1.18,6.13-5.66,5.08-8.29c-0.72-1.8-4.78-1.81-13.46-1.28C1396.22,683.86,1390.64,685.32,1389.56,683.18z"
        />
        <path
          className="st10"
          d="M1372.71,690.87c1.16,0.91,2.96,2.51,4.56,4.93c1.87,2.83,2.26,4.95,4.29,10.13
		c0.65,1.65,1.62,4.02,2.95,6.85"
        />
        <path
          className="st10"
          d="M1253.41,840.58c-2.96,2.87-5.94,5.85-8.94,8.94c-5.15,5.31-9.98,10.57-14.48,15.74"
        />
        <path
          d="M1250.2,893.09c2.02-0.62,6.72,13.31,9.77,12.59c0.71-0.17,1.42-1.15,2.2-10.07c0.84-9.67,1.28-14.64,0.55-22.15
		c-0.79-8.16-2.19-11.41-1.18-11.88c1.72-0.8,8.6,7.33,12.35,17.18c1.07,2.81,2.41,7.15,2.38,32.39
		c-0.02,15.17-0.51,18.58-2.93,19.77c-4.6,2.26-12.48-5.36-13.54-6.41c-5.01-4.96-6.71-10.61-8.24-15.92
		C1249.55,901.67,1248.71,893.55,1250.2,893.09z"
        />
        <path
          className="st9"
          d="M1311.04,346.79c2.36-12.6,23.74-12.53,61.06-28.1c39.09-16.3,31.12-22.84,70.79-39.99
		c11.62-5.02,26.95-10.79,36.2-25.94c3.78-6.18,3.59-9.09,8.11-14.59c12.11-14.75,34.73-19.8,38.37-14.59
		c3.19,4.56-7.89,17.39-16.21,27.02c-17.07,19.76-33.93,30.98-41.61,36.2c-41.88,28.49-34.26,30.67-71.87,55.12
		c-52.82,34.34-66.5,29.16-70.79,27.02C1316.82,364.82,1309.49,355.04,1311.04,346.79z"
        />
        <path
          className="st9"
          d="M1190,319.77c1.64-8.74-4.16-14.67-12.43-29.72c0,0-7.35-13.38-16.21-38.37
		c-4.77-13.46-2.04-10.71-11.89-62.14c-5.68-29.7-7.56-35.66-5.94-47.55c0.83-6.09,2.24-11.3,1.08-19.45
		c-1.7-11.96-7.54-20.54-12.43-27.56c-3.33-4.78-12.45-17.88-16.21-16.21c-3.24,1.44,0.03,12.72,2.16,32.42
		c0.77,7.11,1.05,17.53,1.62,38.37c1.43,52.36-2.65,52.21,0.54,71.33c2.8,16.78,5.31,13.14,18.91,52.42
		c16.04,46.33,16.88,63.9,27.56,65.93C1175.96,340.97,1188.02,330.32,1190,319.77z"
        />
        <path
          className="st11"
          d="M1193.15,307.73c7.21-0.13,7.45,13.27,20.02,20.41c5.63,3.19,9.82,2.96,11.28,6.68
		c1.51,3.84-1.2,8.52-3.71,11.85c-1.74,2.64-4.13,6.73-6.09,12.08c-2.66,7.25-4.51,12.14-2.79,17.24
		c2.51,7.44,11.11,12.93,19.7,12.48c10.29-0.55,16.03-10.03,18.22-13.63c2.47-4.04,3.37-7.66,5.07-14.48
		c1.71-6.85,2.45-12.66,2.8-16.72c2.75-0.06,6.65-0.14,11.33-0.24c17.12-0.33,16.72,0.04,22.38-0.37c15.62-1.13,20.69-3.33,27.23,0
		c6.45,3.28,9.27,9.36,13.05,17.53c4.66,10.06,9.32,20.11,4.48,27.97c-2.6,4.23-7.09,2.65-23.12,8.58
		c-4.31,1.59-7.79,3.08-10.07,4.1c-4.23,11.44-8.45,22.87-12.68,34.31c-5.82,15.74-9.53,21.46-13.43,33.19
		c-4.52,13.59-6.7,25.16-7.83,32.82c2.49,1.74,4.97,3.48,7.46,5.22c16.47,12.06,29.28,23.39,38.41,32.07
		c15.43,14.67,31.02,29.68,45.5,52.96c9.78,15.73,15.78,29.95,19.39,39.91c-2.49,2.73-4.97,5.47-7.46,8.2
		c-6.82,7.93-18.1,19.12-34.68,27.97c-21.36,11.4-40.33,12.96-64.15,14.92c-9.31,0.76-34.74,2.38-67.13-2.61
		c-29.91-4.61-52.17-12.75-66.01-17.9c-13.68-5.09-24.94-10.18-33.19-14.17c1.86-3.61,3.73-7.21,5.59-10.82
		c3.88-6.28,9.75-16.07,16.41-28.34c13.2-24.31,17.33-35.97,30.21-59.3c3.91-7.09,9.78-17.33,17.53-29.46
		c-0.25-7.09-0.5-14.17-0.75-21.26c-0.35-21.29-0.78-38.57-1.12-50.72c-0.38-13.52-0.78-25.54-0.75-42.52
		c0.02-10.83,0.21-19.74,0.37-25.73c-2.11-3.85-4.23-7.71-6.34-11.56c-3.98-7.83-7.96-15.66-11.93-23.5
		c3.98-5.47,7.96-10.94,11.93-16.41c6.26-6.43,12.02-11.63,16.78-15.66C1190.31,308.4,1191.52,307.76,1193.15,307.73z"
        />
        <path
          className="st2"
          d="M794.57,676.66c-11.34,9.05-10.01,27.05-9.68,31.46c0.17,2.25,2,23.04,16.94,32.43
		c8.54,5.37,14,2.38,19.84,8.71c6.56,7.11,2.3,13.72,7.74,21.3c7.76,10.8,29.02,14.9,37.27,6.29c7.92-8.27,1.29-25.57,0.48-27.59
		c-4.7-11.86-11.28-11.75-15.49-22.26c-5.75-14.37,3.76-21.48-0.48-32.91C844.65,676.46,810.96,663.58,794.57,676.66z"
        />
        <path
          className="st3"
          d="M780.79,682.99c-4.26,9.55-3.72,16.66-3.58,18.2c0.53,5.78,3.01,14.79,7.16,15.21
		c3.46,0.36,6.82-5.41,8.35-8.05c4.76-8.2,2.75-13.18,5.67-14.62c5.6-2.75,15.11,14.47,25.06,12.23c6.15-1.39,7.25-9.05,11.93-8.35
		c3.94,0.59,8.03,6.75,9.55,11.93c0.71,2.43,0.66,3.98,1.79,4.47c2.28,0.99,7.01-3.31,9.25-7.46c5.32-9.86-1.4-22.67-3.28-26.25
		c-1.65-3.15-8.3-15.32-23.27-20.58c-3.46-1.22-14.76-5-27.15,0C788.73,665.18,783.31,677.33,780.79,682.99z"
        />
        <path
          className="st3"
          d="M806.21,662.61c-0.94-0.89-2.64-3.16-0.38-16.13c1.86-10.64,2.79-15.97,5.63-21.01
		c6.1-10.85,12.66-9.9,17.63-21.01c2.93-6.54,1.09-7.85,4.13-12.75c6.53-10.56,20.72-13.71,21.76-12c0.82,1.34-7.31,4.27-9.38,12.38
		c-2.24,8.77,5.37,12.69,3.38,21.01c-2.11,8.8-11.7,8.88-12.75,16.88c-1.01,7.71,7.53,10.44,6.38,17.63
		c-1.51,9.42-17.61,13.7-22.51,15.01C814.86,664,809.26,665.49,806.21,662.61z"
        />
        <path
          className="st11"
          d="M816.2,940.68c-12.14,13.82-14.29,31.13-15.51,40.95c-1.81,14.56,0.84,27.28,6.21,52.12
		c2.53,11.7,6.27,27.2,11.79,45.3c-10.14-7.24-20.27-14.48-30.41-21.72c-11.79-9.1-23.58-18.2-35.37-27.3
		c-7.03,8.07-14.07,16.13-21.1,24.2c11.17,12.62,22.34,25.23,33.51,37.85c12.41,11.17,24.82,22.34,37.23,33.51
		c5.44,4.74,18.09,14.4,36.61,16.75c8.22,1.05,26.38,3.35,37.23-7.45c3.67-3.65,6.45-8.74,9.31-37.23
		c1.61-16.01,2.22-29.29,2.48-38.47c6,16.34,12,32.68,18,49.02c1.86,7.03,3.72,14.07,5.58,21.1
		c-10.14,10.14-20.27,20.27-30.41,30.41c-16.75,13.44-33.51,26.89-50.26,40.33c5.38,6.62,10.76,13.24,16.13,19.86
		c22.13-10.96,44.26-21.93,66.4-32.89c14.95-7.61,25.78-16.04,32.89-22.34c11.36-10.08,20.34-18.05,24.2-31.03
		c3.01-10.14,1.47-18.53-0.62-29.16c-7.25-36.91-10.88-55.37-13.65-65.16c-8.79-31.01-16.93-51.06-26.68-75.08
		c-9.9-24.39-12.18-27.09-14.27-29.16C892.55,912.37,840.31,913.24,816.2,940.68z"
        />
        <path
          className="st12"
          d="M823.35,762.54c2.67,3.94,7.86,10.27,16.51,14.26c5.31,2.45,13.9,6.4,21.58,2.3
		c4.65-2.48,8.57-7.71,9.08-13.31c0.7-7.67-5.41-11.54-3.35-16.52c1.53-3.7,6.24-4.78,9.74-5.85c0,0,11.1-1.27,27.87-16.67
		c18.02-16.54,23.79-38.24,26.78-49.46c2.87-10.77,6.75-25.34,3.55-43.45c-2.39-13.56-8.21-26.04-13.39-37.16
		c-2.82-6.05-5.44-10.99-7.38-14.48c6.38-5.19,12.75-10.38,19.13-15.58c5.28,7.1,10.57,14.21,15.85,21.31
		c13.13,18.56,21.77,35.03,27.33,47c8.87,19.12,10.18,27.4,10.66,31.7c1.33,11.92-0.57,21.61-5.74,39.62
		c-6.87,23.94-10.13,29.88-18.85,56.02c-11.54,34.58-9.55,35.34-18.85,59.57c-3.15,8.2-5.83,14.5-8.47,24.87
		c-0.76,2.98-4.17,16.68-5.19,37.44c-1.12,22.62,1.78,29.41-0.55,53.29c-0.54,5.53-1.18,10.08-1.64,13.12
		c-3.64,1.91-7.29,3.83-10.93,5.74c-6.65,3.85-15.44,7.96-26.23,10.66c-25.98,6.49-50,0.13-57.38-2.19
		c-10.29-3.23-18.19-7.39-23.23-10.38c-0.91-8.93-1.82-17.85-2.73-26.78c-0.74-8.3-3.04-21.26-10.93-34.43
		c-3.19-5.33-10.78-16.5-35.52-32.24c-34.41-21.9-41.85-14.37-59.84-30.88c-13.94-12.78-20.75-27.64-29.51-46.73
		c-8.49-18.5-11.65-32.94-12.84-39.08c-1.17-6.05-1.81-11.22-2.19-15.03c7.74-2.19,15.48-4.37,23.23-6.56
		c2.28,5.65,4.55,11.29,6.83,16.94c6.45,11.74,12.76,20.8,17.49,27.05c12.25,16.23,17.76,18.82,21.04,19.95
		c3.96,1.36,8.03,1.72,21.59-0.55c20.64-3.44,36.17-8.44,40.44-9.84C796.64,773.14,809.01,768.73,823.35,762.54z"
        />
        <path
          className="st2"
          d="M660.22,731.11c-3.39-2.47-2.12-6.85-3.67-18.64c-1.38-10.49-2.71-9.46-3.68-18.64
		c-1.48-14.02,1.16-20.69,2.1-22.84c1.47-3.36,3.82-8.72,7.35-8.92c4.9-0.29,11.18,9.41,10.5,18.11c-0.45,5.76-3.55,6.07-4.99,13.39
		c-1.19,6.04-0.1,11.04,1.31,17.59c2.48,11.47,5.85,14.83,3.68,18.11C670.56,732.7,663.81,733.72,660.22,731.11z"
        />
        <path
          className="st2"
          d="M921.9,583.19c-6.66-0.66-7.82-7.43-16.32-15.15c-11.67-10.61-18.99-6.48-25.06-15.15
		c-6.43-9.19-5.68-24.51,0.58-27.97c7.21-3.99,20.49,8.4,22.73,10.49c7.43,6.94,5.39,10.4,13.4,20.98
		c9.5,12.55,17.52,15.29,16.32,20.4C932.63,580.73,926.74,583.66,921.9,583.19z"
        />
        <path
          className="st1"
          d="M754.23,1035.19c-0.21-6.15-5.18-10.33-10.52-14.81c-4.52-3.8-9.12-7.65-15.98-7.79
		c-8.15-0.17-13.93,5.02-15.59,6.62c-11.04,8.44-22.08,16.88-33.12,25.33c-2.19,2.05-5.17,5.24-7.79,9.74
		c-3.18,5.44-4.97,8.51-3.9,10.52c2.59,4.84,20.83,1.67,34.29-3.51c10.48-4.03,13.35-7.46,22.6-8.18c7.43-0.58,9.36,1.35,14.81-0.39
		C746.25,1050.42,754.51,1043.35,754.23,1035.19z"
        />
        <path
          className="st1"
          d="M837.04,1199.95c-4.89-1.13-9.26,2.47-16.53,8.47c-7.25,5.98-10.87,8.96-12.09,13.71
		c-1.52,5.9,1.89,9.03,8.06,22.17c8.85,18.84,7.01,23.39,14.92,32.65c1.44,1.69,11.32,13.25,16.53,10.88
		c3.56-1.62,3.1-11.85,2.02-32.25c-1.1-20.84-2.41-17.75-2.02-27.01c0.3-6.95,1.18-12.13-1.61-18.54
		C845.24,1207.55,842.47,1201.2,837.04,1199.95z"
        />
        <circle className="st12" cx="619.19" cy="458.68" r="15.33" />
        <circle className="st11" cx="1051.17" cy="524.12" r="15.33" />
        <circle className="st6" cx="224.67" cy="296.07" r="15.33" />
        <circle className="st13" cx="1450.59" cy="829.12" r="15.33" />
        <circle className="st6" cx="817.54" cy="358.81" r="15.33" />
        <circle className="st6" cx="465.35" cy="1151.34" r="15.33" />
        <circle className="st11" cx="1172.63" cy="1027.91" r="15.33" />
        <circle className="st12" cx="1541.46" cy="1136.01" r="15.33" />
        <circle className="st12" cx="1642.72" cy="415.4" r="15.33" />
        <circle className="st11" cx="157.42" cy="916.12" r="15.33" />
        <circle className="st11" cx="1465.92" cy="501.96" r="15.33" />
        <circle className="st13" cx="1066.5" cy="829.12" r="15.33" />
        <circle className="st13" cx="1081.84" cy="1182" r="15.33" />
        <circle className="st12" cx="673.52" cy="894.14" r="15.33" />
      </g>
    </SVG>
  )
}

export default MeriIllust

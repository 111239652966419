export const getWebsiteData = async (url: string) => {
  const storage = localStorage.getItem("cnw_" + url) || null

  const isCached =
    storage &&
    JSON.parse(storage).date === new Date().toISOString().split("T")[0]

  if (storage && isCached) {
    return JSON.parse(JSON.parse(storage).data)
  }

  try {
    const response = await fetch(process.env.GATSBY_API_URL + "", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ url }),
    })
    const result = await response.json()

    if (result.statusCode === 200) {
      localStorage.setItem(
        "cnw_" + url,
        JSON.stringify({
          date: new Date().toISOString().split("T")[0],
          data: result.body,
        })
      )
    }

    return JSON.parse(result.body)
  } catch (e) {
    return { error: true }
  }
}

import tw, { styled } from "twin.macro"

export const Header = styled.header`
  ${tw`relative overflow-hidden`}

  background: #EDF0AB;
  min-height: 85vh;
  max-height: 45rem;

  @media (min-width: 400px) {
    min-height: 70vh;
  }
  @media (min-width: 640px) {
    min-height: 60vh;
  }
  @media (min-width: 768px) {
    height: 63vw;
    min-height: 0;
  }

  @media (min-width: 1024px) {
    height: 56vw;
    max-height: 85vh;
  }
`

export const Overlay = styled.div`
  ${tw`inset-0 bg-black opacity-20 md:opacity-10 absolute`}
`

export const Hero = styled.div`
  ${tw`container mx-auto w-11/12 md:w-4/5 relative z-10 flex items-center h-full py-20 md:py-16`}
`
export const HeroWrap = styled.div`
  ${tw`w-full flex flex-col items-center justify-between relative z-10`}
`

export const Title = styled.h2`
  font-size: 2rem;
  ${tw`flex flex-col items-center font-extrabold sm:text-3xl md:text-4xl text-center lg:text-5xl text-primary leading-9 sm:leading-7 md:leading-7 lg:leading-none mb-2`}
  span {
    ${tw`md:block mb-2 md:mb-4`}
  }
`

export const SubTitle = styled.h3`
  ${tw`font-bold md:font-normal text-lg sm:text-xl lg:text-2xl text-center text-white mb-4 lg:mb-6 tracking-tighter`}
  text-shadow: 0 0 1px black;

  @media (min-width: 768px) {
    text-shadow: 0 0 1px lightgray;
  }
`

export const Text = styled.p`
  ${tw`flex flex-col  md:text-center items-center font-extrabold lg:text-lg mt-2 text-white`}
  text-shadow: 0 0 2px black;

  br {
    ${tw`hidden md:block`}
  }

  &:last-of-type {
    ${tw`mb-4`}
  }
`

export const HeroBottom = styled.div`
  ${tw`top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden`}
  height:10vh;
  svg {
    ${tw`absolute bottom-0 overflow-hidden h-full w-full`}
    path {
      ${tw`fill-current text-gray-100`}
    }
  }
`

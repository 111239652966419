import React from "react"
import MeriIllust from "../../svg/MeritIllust"

import * as Styled from "./styles"

const merits = [
  {
    name: "ユーザーフレンドリー",
    description: {
      __html:
        "排出量軽減のための施策を行うことで、ページ読み込み速度の向上に繋がり、あなたのファンの離脱率、回遊率の改善に繋がります。<br><small>※ウェブサイトデザインが明らかに悪くなる改善は必要ありません</small>",
    },
  },
  {
    name: "SEOの改善",
    description: {
      __html:
        "上記の改善により、検索エンジンによるサイト評価、検索順位の向上も見込まれます。",
    },
  },
  {
    name: "本質的な社会・ファンへの貢献",
    description: {
      __html:
        "社会貢献による単なるブランド価値の向上だけでなく、あなたのファンが使用する端末の通信データ量の軽減や、端末による電力消費の軽減にも寄与できます。",
    },
  },
]

export default function Merits() {
  return (
    <Styled.Section id="merits">
      <Styled.Container>
        <Styled.SectionSubTitle>Merits</Styled.SectionSubTitle>
        <Styled.SectionTitle>
          CO2 排出量を軽減することは、 実は、あなたにも素晴らしいメリットが
        </Styled.SectionTitle>
        <Styled.FlexWrapper>
          <Styled.MeritHalf>
            <Styled.MeritList>
              {merits.map((merit, i) => (
                <Styled.MeritListItem key={i}>
                  <Styled.MeritIcon>
                    <svg viewBox="0 0 20 20" fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </Styled.MeritIcon>
                  <Styled.Merit>
                    <Styled.MeritTitle>{merit.name}</Styled.MeritTitle>
                    <Styled.MeritText
                      dangerouslySetInnerHTML={merit.description}
                    />
                  </Styled.Merit>
                </Styled.MeritListItem>
              ))}
            </Styled.MeritList>
          </Styled.MeritHalf>
          <Styled.MeritHalf>
            <Styled.MeritIllust>
              <MeriIllust />
            </Styled.MeritIllust>
          </Styled.MeritHalf>
        </Styled.FlexWrapper>
      </Styled.Container>
      <Styled.SectionBottom>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
        >
          <path d="M0,192L60,202.7C120,213,240,235,360,234.7C480,235,600,213,720,197.3C840,181,960,171,1080,192C1200,213,1320,267,1380,293.3L1440,320L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
        </svg>
      </Styled.SectionBottom>
    </Styled.Section>
  )
}

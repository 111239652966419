import tw, { styled } from "twin.macro"

import * as Common from "../../common/styles"

export const Section = styled(Common.Section)`
  ${tw`pt-20 pb-36 bg-gray-100`};
`

export const SectionBottom = styled.div`
  ${tw`top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden`}
  height:10vh;
  svg {
    ${tw`absolute bottom-0 overflow-hidden h-full w-full`}
    path {
      ${tw`fill-current text-primary`}
    }
  }
`
export const Container = tw(Common.Container)`mx-auto`
export const SectionSubTitle = Common.SectionSubTitle
export const SectionTitle = Common.SectionTitle
export const SectionHeader = Common.SectionHeader
export const SectionText = Common.SectionText

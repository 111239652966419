import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`mt-8 w-full mx-auto max-w-3xl text-white`}
`
export const InputGroup = styled.div`
  ${tw`flex rounded-md shadow-sm bg-white `}
  label {
    ${tw`sr-only`}
  }
`
export const Button = styled.button`
  ${tw`inline-block px-5 md:px-12 py-2 md:py-3 text-xs sm:text-sm font-medium leading-6 text-center uppercase transition bg-primary rounded-md shadow hover:shadow-lg hover:bg-tertiary focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed`}
`

export const Input = styled.input`
  ${tw`focus:border-tertiary flex-1 block w-full rounded-none rounded-r-md text-xs sm:text-sm border-transparent px-4 text-primary`}
`

export const Select = styled.select`
  ${tw`focus:border-tertiary h-full py-0 pl-2 md:pl-4 pr-2 md:pr-8 border-transparent bg-gray-100 text-gray-400 text-xs md:text-sm rounded-md`}
`

export const Prefix = styled.span`inline-flex items-center rounded-l-md rounded-r-0 border border-r-0 border-transparent bg-gray-100 text-gray-500 text-sm
`

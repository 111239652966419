import tw, { styled } from "twin.macro"
import * as Common from "../../common/styles"

export const SectionHeader = Common.SectionHeader
export const Container = Common.Container
export const SectionTitle = Common.SectionTitle
export const SectionSubTitle = Common.SectionSubTitle
export const SectionText = Common.SectionText
export const GridWrapper = Common.GridWrapper

export const Section = tw(Common.Section)`bg-white`

export const SolutionCard = styled.div``
export const SolutionIcon = styled.div`
  ${tw`flex items-center justify-center h-12 w-12 rounded-md text-white bg-tertiary`}

  svg {
    ${tw`h-8 w-8`}
  }
`

export const SolutionTitle = styled.div`
  ${tw`text-xl font-black leading-6 text-primary mt-4`}
`

export const SolutionText = styled.div`
  ${tw`text-base text-gray-500 mt-2`}
`

export const SectionBottom = styled.div`
  ${tw`top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden`}
  height:10vh;
  svg {
    ${tw`absolute bottom-0 overflow-hidden h-full w-full`}
    path {
      ${tw`fill-current text-tertiary text-opacity-20`}
    }
  }
`

import tw, { styled } from "twin.macro"

export const BackgroundWrap = styled.div`
  ${tw`absolute md:h-full w-full md:object-cover`}
  height:130%;

  svg {
    ${tw`h-full md:h-auto md:w-full absolute`}
    top: 50%;
    transform: translateY(-50%);
  }
`

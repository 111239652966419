import React from "react"

function Problems01() {
  return (
    <svg
      id="e214ade4-f01b-42f1-81dc-150adaeb3ca1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="800.51365"
      height="631.85072"
      viewBox="0 0 800.51365 631.85072"
    >
      <path
        d="M866.141,628.92775,829.39349,594.6221l-17.42584-16.07268a14.1379,14.1379,0,0,1,2.69428-22.73713h0a14.1379,14.1379,0,0,1,19.85155,6.69618l5.73171,13.1512,35.59506,36.922Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#ffb9b9"
      />
      <path
        d="M870.94394,606.67614,858.0396,628.17086l52.38655,32.1347c46.18433-33.19688,55.32672-44.00053,44.011-96.8802l-9-9-43.51726,70.581Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#9acb56"
      />
      <path
        d="M794.86732,546.91441a3.17256,3.17256,0,0,0-3.97134-.3909l-24.25934,15.80083a3.19147,3.19147,0,0,0-.5101,4.93544l61.694,61.42676a3.19121,3.19121,0,0,0,4.5466-.04391l20.218-20.92546a3.19828,3.19828,0,0,0-.06531-4.50042Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#3f3d56"
      />
      <path
        d="M771.41575,564.01452l2.51058-1.60071a2.392,2.392,0,0,0,3.44276,3.14072l16.24627-11.16932a2.39058,2.39058,0,0,0-.63206-4.25032l1.29346-.82469a.87035.87035,0,0,1,1.087.12213l54.65363,53.70972a.87032.87032,0,0,1-.022,1.245L830.14649,623.7275a.87035.87035,0,0,1-1.21147-.01682l-57.66577-58.34587A.87033.87033,0,0,1,771.41575,564.01452Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#fff"
      />
      <polygon
        points="634.751 457.208 617.985 473.016 578.106 433.616 598.345 420.442 634.751 457.208"
        fill="#9acb56"
      />
      <path
        d="M779.3993,577.41385a.58112.58112,0,0,0,0-.82055l-3.48743-3.48736a.59434.59434,0,0,0-.82062,0,.58113.58113,0,0,0,0,.82054l3.48744,3.48737a.59433.59433,0,0,0,.82061,0Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#e6e6e6"
      />
      <path
        d="M785.27407,582.46807l-3.48743-3.48743a.58026.58026,0,0,0-.82062.82061l3.48744,3.48744a.58026.58026,0,1,0,.82061-.82062Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#e6e6e6"
      />
      <circle cx="634.36406" cy="470.77668" r="3.91651" fill="#9acb56" />
      <polygon
        points="800.132 609.239 496.394 609.239 496.394 607.133 800.514 607.133 800.132 609.239"
        fill="#3f3d56"
      />
      <polygon
        points="765.401 607.864 765.401 304.126 767.507 304.126 767.507 608.245 765.401 607.864"
        fill="#3f3d56"
      />
      <path
        d="M582.42821,732.92658l-19.89631,5.25263v0a24.82477,24.82477,0,0,1-31.63782,11.72141l-.43459-.17983a29.70031,29.70031,0,0,1-7.30216-4.45825c-8.35656-6.774-22.27915-10.27006-40.74163-11.0926a5.34958,5.34958,0,0,1-3.73826-5.38464h0a5.3496,5.3496,0,0,1,4.65028-5.02349l99.55254-12.98511Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#e6e6e6"
      />
      <path
        d="M840.54192,750.55607c-52.051-8.34748-99.46966-21.85879-139.22771-43.9096L577.90783,736.933l.452-27.06035,96.46128-33.83343a122.5869,122.5869,0,0,1,50.75383-6.48573l114.96694,21.33357Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#2f2e41"
      />
      <path
        d="M642.10034,761.41533h0a6.32849,6.32849,0,0,1,2.60126-7.11715l46.66778-24.53585,11.30095-29.38247,19.43764,12.205L716.6835,734.2827l1.2827,8.97885A19.85578,19.85578,0,0,1,698.31,765.92536H648.162A6.3285,6.3285,0,0,1,642.10034,761.41533Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#e6e6e6"
      />
      <path
        d="M903.3752,733.83066c-56.35808,70.069-95.13546-6.24968-133.35121-87.24334l-46.10788,75.03831L695.88975,701.736l48.32706-106.31953a18.2009,18.2009,0,0,1,28.94021-5.819l67.3849,75.0714,12.65706-.90407Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#2f2e41"
      />
      <circle cx="706.79629" cy="366.05237" r="33.45081" fill="#ffb9b9" />
      <path
        d="M949.031,559.796l-37.06712,8.13669A223.8748,223.8748,0,0,0,896.59463,523.633l34.35489-12.65706A237.74055,237.74055,0,0,0,949.031,559.796Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#ffb9b9"
      />
      <path
        d="M906.53947,742.41938l-65.99755-77.75054L867.41644,619.999c2.82866-20.09466,13.20743-35.88912,29.17819-48.4989l10.84891-18.03263,32.54674-13.56114,0,0a66.60524,66.60524,0,0,1,19.75654,78.36242Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#9acb56"
      />
      <path
        d="M849.58268,656.53216l-39.77935-30.73859-18.84539-14.382a14.13789,14.13789,0,0,1,.56756-22.88916l0,0a14.13789,14.13789,0,0,1,20.38835,4.82052l6.93021,12.561,38.87527,33.45081Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#ffb9b9"
      />
      <path
        d="M852.29491,633.93026,841.446,656.53216l55.14864,27.12228c42.896-37.34909,66.81389-73.16471,50.62826-124.76249l-20.79375,5.42445-41.5875,84.98315Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#9acb56"
      />
      <circle cx="750.2228" cy="314.18248" r="13.31844" fill="#2f2e41" />
      <path
        d="M936.24248,444.99657a13.308,13.308,0,0,1,13.31213-12.448c-.275-.03489-.54757-.07731-.828-.09534a13.31844,13.31844,0,0,0-1.709,26.582c.28043.018.55617.01087.83339.01148A13.30794,13.30794,0,0,1,936.24248,444.99657Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#2f2e41"
      />
      <path
        d="M936.16732,450.6839c-11.87785-9.85348-45.94685,2.66987-48.1453,9.29449-16.06228,2.189-28.22339,11.56031-28.22339,28.2233v.00009c9.54953,3.91207,20.41026,3.43,32.15068,0,18.7152,15.25179,19.99817,34.42492,8.61644,56.44679l14.0026.28,17.35673-25.36748.83,25.73124,32.09728-.64378v-36.8745a77.09491,77.09491,0,0,0-8.2315-35.32C951.61907,462.73671,943.8568,457.06281,936.16732,450.6839Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#2f2e41"
      />
      <ellipse
        cx="706.53518"
        cy="372.84179"
        rx="6.36891"
        ry="8.91648"
        fill="#ffb8b8"
      />
      <path
        d="M206.74317,134.07464a7.00787,7.00787,0,0,0-7,7v296a7.00776,7.00776,0,0,0,7,6.99951h434a7.00807,7.00807,0,0,0,7-6.99951v-296a7.00818,7.00818,0,0,0-7-7Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#e6e6e6"
      />
      <path
        d="M216.74317,144.07464a7.00787,7.00787,0,0,0-7,7V427.07415a7.00786,7.00786,0,0,0,7,7h414a7.00817,7.00817,0,0,0,7-7V151.07464a7.00818,7.00818,0,0,0-7-7Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#fff"
      />
      <rect x="95.63674" y="194.05944" width="2" height="6" fill="#e6e6e6" />
      <rect
        x="95.63674"
        y="210.99401"
        width="2"
        height="10.93506"
        fill="#e6e6e6"
      />
      <rect x="95.63674" y="232.86364" width="2" height="6" fill="#e6e6e6" />
      <rect x="290.63674" y="109.99987" width="2" height="6" fill="#e6e6e6" />
      <path
        d="M492.37991,351.28691h-2V338.63506h2Zm0-25.30322h-2V313.33232h2Zm0-25.30322h-2V288.0291h2Zm0-25.30323h-2V262.72588h2Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#e6e6e6"
      />
      <rect x="290.63674" y="229.86364" width="2" height="6" fill="#e6e6e6" />
      <rect x="403.63674" y="53.99987" width="2" height="6" fill="#e6e6e6" />
      <path
        d="M605.37991,352.61406h-2V341.28984h2Zm0-22.64844h-2V318.64141h2Zm0-22.64843h-2V295.993h2Zm0-22.64844h-2V273.34453h2Zm0-22.64893h-2V250.6956h2Zm0-22.64843h-2V228.04717h2Zm0-22.64844h-2V205.39873h2Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#e6e6e6"
      />
      <rect x="403.63674" y="229.86364" width="2" height="6" fill="#e6e6e6" />
      <path
        d="M262.73189,372.52241c-4.813-14.42246,4.22195-29.30532,16.29851-36.92968,15.36878-9.70286,34.88621-6.81545,51.05368-.80028,31.83151,11.843,60.34673,39.52858,96.707,33.00206a46.17891,46.17891,0,0,0,19.92151-8.45717,41.70233,41.70233,0,0,0,13.40778-18.44248c5.77408-15.04366,4.39716-31.45568,5.29457-47.21875.83147-14.60474,3.734-31.212,15.73237-40.99253,13.14488-10.71516,31.77382-8.63587,47.585-9.67266a88.08855,88.08855,0,0,0,42.69551-14.11346,89.38269,89.38269,0,0,0,30.253-33.0409,85.14915,85.14915,0,0,0,4.40044-9.95569c.67012-1.81353-2.22828-2.596-2.89284-.79752-10.61535,28.7281-38.07423,50.64686-68.52535,54.36895-16.061,1.96316-33.44561-.75764-48.47513,6.501-14.15142,6.83455-20.43162,21.64425-22.6383,36.41411-2.36978,15.86163-.40025,32.08153-3.53554,47.8534-1.563,7.86236-4.46638,15.59717-9.784,21.71574a39.33389,39.33389,0,0,1-18.94486,11.71427c-35.65983,10.30809-66.11355-17.88242-96.93583-30.42045-16.04143-6.52541-35.24131-10.37895-51.694-3.00874-12.81448,5.74044-23.706,18.62167-24.26874,33.05821a28.2318,28.2318,0,0,0,1.45235,10.02009c.60813,1.82227,3.50643,1.04112,2.89284-.79752Z"
        transform="translate(-199.74317 -134.07464)"
        fill="#9acb56"
      />
      <path
        d="M241.74319,325.9508"
        transform="translate(-199.74317 -134.07464)"
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="2"
        strokeDasharray="12"
      />
      <polygon
        points="423 239.864 41 239.864 41 51.136 43 51.136 43 237.864 423 237.864 423 239.864"
        fill="#3f3d56"
      />
    </svg>
  )
}

export default Problems01

import React from "react"
import { FaRuler, FaCogs, FaBalanceScale, FaCertificate } from "react-icons/fa"

import * as Styled from "./styles"

const features = [
  {
    icon: FaRuler,
    name: "CO2排出量の視える化",
    description:
      "ウェブサイトのページサイズ、ユーザ統計、データ転送などから独自システムによる定量的な評価・算定を行います。",
  },
  {
    icon: FaCogs,
    name: "排出量の軽減/ユーザビリティの向上",
    description:
      "ウェブサイトを最適化し、CO2 排出量を削減するだけでなく、サイトパフォーマンス、SEOおよびCV率向上のためのアドバイス・改善を行います。",
  },
  {
    icon: FaBalanceScale,
    name: "カーボンオフセットの導入支援",
    description:
      "削減しきれないCO2排出に関しては、その量を算定し、同等量のカーボンオフセットの導入を支援します。",
  },
  {
    icon: FaCertificate,
    name: "審査および認証マークの発行",
    description:
      "審査およびCNW認証マークの発行・提供を行います。また発行団体・会社は、当HPに一覧で確認できるよう公開致します。",
  },
]

export default function Solutions() {
  return (
    <Styled.Section id="solutions">
      <Styled.Container>
        <Styled.SectionHeader>
          <Styled.SectionSubTitle>Solutions</Styled.SectionSubTitle>
          <Styled.SectionTitle>CNWの取り組み</Styled.SectionTitle>
          <Styled.SectionText>
            平均的な規模のサイトでは、年間4,700ポンドのCO2eが排出されます。
            <br />
            EPAによると、これは90万リットルのガソリンを消費することに相当します。
            <br />
            Carbon Neutral Webでは、このCO2e
            (フットプリント)を抑制するための取り組みをしています。
          </Styled.SectionText>
        </Styled.SectionHeader>

        <Styled.GridWrapper>
          {features.map((feature) => (
            <Styled.SolutionCard key={feature.name}>
              <Styled.SolutionIcon>
                {feature.icon && <feature.icon />}
              </Styled.SolutionIcon>
              <Styled.SolutionTitle>{feature.name}</Styled.SolutionTitle>
              <Styled.SolutionText>{feature.description}</Styled.SolutionText>
            </Styled.SolutionCard>
          ))}
        </Styled.GridWrapper>
      </Styled.Container>
      <Styled.SectionBottom>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
        >
          <path d="M0,128L60,117.3C120,107,240,85,360,117.3C480,149,600,235,720,245.3C840,256,960,192,1080,160C1200,128,1320,128,1380,128L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
        </svg>
      </Styled.SectionBottom>
    </Styled.Section>
  )
}

import tw, { styled } from "twin.macro"

import * as Common from "../../common/styles"

export const Section = styled(Common.Section)`
  ${tw`pt-20 pb-36 bg-white`};
`

export const SectionBottom = styled.div`
  ${tw`top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden`}
  height:10vh;
  svg {
    ${tw`absolute bottom-0 overflow-hidden h-full w-full`}
    path {
      ${tw`fill-current text-white`}
    }
  }
`
export const Container = Common.Container
export const SectionSubTitle = Common.SectionSubTitle
export const SectionTitle = Common.SectionTitle
export const SectionHeader = Common.SectionHeader
export const SectionText = Common.SectionText
export const FlexWrapper = tw(
  Common.FlexWrapper
)`items-center flex-col-reverse md:flex-row space-y-6 md:mt-8`
export const MeritHalf = tw.div`w-full md:w-1/2 mt-0`
export const MeritIllust = styled.div`
  svg {
    ${tw`mx-auto w-5/6 md:w-full`}
  }
`
export const MeritList = tw.ul`mt-8 md:grid md:grid-cols-1 gap-6`
export const MeritListItem = tw.li`mt-6 lg:mt-0 flex`
export const MeritIcon = styled.div`
  ${tw`flex-shrink-0 flex items-center justify-center h-8 w-8 rounded-full bg-tertiary text-primary `}
  svg {
    ${tw`h-6 w-6`}
  }
`
export const Merit = tw.div`ml-4 text-base leading-6 font-medium text-gray-900`
export const MeritTitle = tw.h3`text-xl mb-2 font-bold`
export const MeritText = tw.p``

import tw, { styled } from "twin.macro"
import Img from "gatsby-image"

import * as Common from "../../common/styles"

export const Section = styled(Common.Section)`
  ${tw`pt-0 pb-36 bg-white`};
`

export const Container = Common.Container
export const SectionSubTitle = Common.SectionSubTitle
export const SectionTitle = styled(Common.SectionTitle)`
  ${tw`mb-10`}
`
export const SectionHeader = Common.SectionHeader
export const SectionText = Common.SectionText

export const TextRight = tw.p`text-right`
export const Media = tw.div`flex flex-wrap items-center justify-center gap-5 md:gap-10`
export const MediaImg = styled(Img)`
  ${tw`w-1/4 md:w-1/6`}
`

export const SectionBottom = styled.div`
  ${tw`top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden`}
  height:10vh;
  svg {
    ${tw`absolute bottom-0 overflow-hidden h-full w-full`}
    path {
      ${tw`fill-current text-gray-100`}
    }
  }
`

import React from "react"
import Browser from "./Browser"

import * as Styled from "./styles"

export default function Certificate() {
  return (
    <Styled.Section id="certificate">
      <Styled.Container>
        <Styled.SectionHeader center>
          <Styled.SectionSubTitle>Certificate</Styled.SectionSubTitle>
          <Styled.SectionTitle>
            CNW (Carbon Neutral Website) 認証マーク　　
          </Styled.SectionTitle>
        </Styled.SectionHeader>

        <Styled.Wrapper>
          <Styled.Half>
            <Browser />
          </Styled.Half>
          <Styled.Half>
            <Styled.CertificateTitle>
              本認証制度について
            </Styled.CertificateTitle>
            <p>
              本認証制度は、「第三者機関として、当組織によって定められた科学的根拠
              に基づいた基準に従って、厳正公平なる審査及び、定期的な調査に対し、通過
              されたウェブサイトのみが、この認証マークの使用が認められる制度」となっ
              ております。
              <br />
              <br />
            </p>
            <Styled.CertificateTitle>審査方法</Styled.CertificateTitle>
            <p>
              科学的根拠に基づき、ウェブサイト使用によって発生する電力消費・CO2排出量
              の算定結果をもって審査します。同等量のカーボンオフセットの実施を確認できる
              証明書の提示もしくは、当協会へのデポジットを持って、発行されます。
            </p>
          </Styled.Half>
        </Styled.Wrapper>
        <Styled.Box>
          <Styled.BoxTitle>
            Certification Standards
            <br />
            <small>認証基準</small>
          </Styled.BoxTitle>
          <Styled.Standards>
            <Styled.Standard>Data Center/Server</Styled.Standard>
            <Styled.Standard>Network</Styled.Standard>
            <Styled.Standard>User Device</Styled.Standard>
          </Styled.Standards>
          <p>
            GHGプロトコルにおけるScope3に該当する、ユーザによるWEBサイトの使用において、”データセンター（サーバ）・ネットワ
            ーク（通信）・ユーザデバイス”における電力消費から換算されるCO2排出量が、実質的にニュートラル化されていること。
            <br />
            <br />
            <small>
              ※Scope3とは？
              <br />
              GHGプロトコルが策定した温室効果ガスのサプライチェーン排出量の算定・報告のための世界的な基準・ガイドラインにおける排出量の算定範囲
            </small>
          </p>
        </Styled.Box>

        <Styled.Box>
          <Styled.BoxTitle>
            Basic Concept of Our Certification
            <br /> <small>認証における基本指針</small>
          </Styled.BoxTitle>
          <p>
            当組織では、ただカーボンオフセットをするだけでなく、まずはCO2排出量をできる限り軽減することを推奨しています。
            <br />
            <br />
            なぜなら現行の日本におけるカーボンオフセット制度では、間接的なCO2排出量軽減に寄与できますが、資金提供・クレジットによるオフセットの前に、まず実質的に排出量を削減することが重要であるからです。
            <br />
            <br />
            ウェブサイトの性質上、事業や活動のために必要な範囲で、ユーザビリティを悪化させずに対応可能なアップデートを、適切に実
            施することを優先的にご案内します。
          </p>
        </Styled.Box>
      </Styled.Container>
      <Styled.SectionBottom>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
        >
          <path d="M0,160L48,160C96,160,192,160,288,181.3C384,203,480,245,576,256C672,267,768,245,864,229.3C960,213,1056,203,1152,218.7C1248,235,1344,277,1392,298.7L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
        </svg>
      </Styled.SectionBottom>
    </Styled.Section>
  )
}

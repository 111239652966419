import React from "react"

import * as Styled from "./styles"
import { graphql, useStaticQuery } from "gatsby"

export default function Flow() {
  const data = useStaticQuery(
    graphql`
      {
        media01: file(relativePath: { eq: "media01.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        media02: file(relativePath: { eq: "media02.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        media03: file(relativePath: { eq: "media03.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        media04: file(relativePath: { eq: "media04.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        media05: file(relativePath: { eq: "media05.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        media06: file(relativePath: { eq: "media06.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        media07: file(relativePath: { eq: "media07.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        media08: file(relativePath: { eq: "media08.png" }) {
          childImageSharp {
            fluid(maxWidth: 200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  return (
    <Styled.Section id="media">
      <Styled.Container>
        <Styled.SectionHeader>
          <Styled.SectionSubTitle>Media</Styled.SectionSubTitle>
          <Styled.SectionTitle>メディア掲載実績</Styled.SectionTitle>
        </Styled.SectionHeader>
        <Styled.Media>
          <Styled.MediaImg fluid={data.media01.childImageSharp.fluid} />
          <Styled.MediaImg fluid={data.media02.childImageSharp.fluid} />
          <Styled.MediaImg fluid={data.media03.childImageSharp.fluid} />
          <Styled.MediaImg fluid={data.media04.childImageSharp.fluid} />
          <Styled.MediaImg fluid={data.media05.childImageSharp.fluid} />
          <Styled.MediaImg fluid={data.media06.childImageSharp.fluid} />
          <Styled.MediaImg fluid={data.media07.childImageSharp.fluid} />
          <Styled.MediaImg fluid={data.media08.childImageSharp.fluid} />
        </Styled.Media>

        <Styled.TextRight>etc...</Styled.TextRight>
      </Styled.Container>
      <Styled.SectionBottom>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
          preserveAspectRatio="none"
        >
          <path d="M0,192L60,202.7C120,213,240,235,360,234.7C480,235,600,213,720,197.3C840,181,960,171,1080,192C1200,213,1320,267,1380,293.3L1440,320L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
        </svg>
      </Styled.SectionBottom>
    </Styled.Section>
  )
}

import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import * as Common from "../../common/styles"

export const Container = Common.Container

export const Section = styled(Common.Section)`
  ${tw`bg-primary pb-24`}
`

export const SectionSubTitle = styled(Common.SectionSubTitle)`
  ${tw`text-tertiary`}
`
export const SectionTitle = styled(Common.SectionTitle)`
  ${tw`text-white`}
`
export const SectionHeader = Common.SectionHeader

export const SectionText = styled(Common.SectionText)`
  ${tw`text-white`}
`

export const BlockLink = tw(
  Link
)`flex items-center justify-center text-white mt-8`

export const SectionBottom = styled.div`
  ${tw`top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden`}
  height:10vh;
  svg {
    ${tw`absolute bottom-0 overflow-hidden h-full w-full`}
    path {
      ${tw`fill-current text-gray-300`}
    }
  }
`

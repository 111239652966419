import tw, { styled } from "twin.macro"

import * as Common from "../../common/styles"

export const Section = styled(Common.Section)`
  ${tw`pt-20 pb-36 bg-gray-100`};
`

export const SectionBottom = styled.div`
  ${tw`top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden`}
  height:10vh;
  svg {
    ${tw`absolute bottom-0 overflow-hidden h-full w-full`}
    path {
      ${tw`fill-current text-white`}
    }
  }
`
export const Container = Common.Container
export const SectionSubTitle = Common.SectionSubTitle
export const SectionTitle = Common.SectionTitle
export const SectionHeader = Common.SectionHeader
export const SectionText = Common.SectionText
export const FlexWrapper = Common.FlexWrapper

export const ProblemCard = styled.div`
  ${tw`w-full lg:w-4/12 lg:text-center`}
`

export const ProblemCardWrap = styled.div`
  ${tw`flex flex-col min-w-0`}
`

export const ProblemCardIcon = styled.div`
  ${tw`flex items-center justify-center h-64 md:h-40 w-full mb-2`}

  svg {
    ${tw`w-64 h-64 md:w-40 md:h-40 `}
  }
`
export const ProblemCardTitle = styled.div`
  ${tw`text-xl font-semibold whitespace-nowrap`}
  br {
    ${tw`hidden xl:block`}
  }
`
export const ProblemCardText = styled.div`
  ${tw`mt-2 mb-4 text-gray-600 whitespace-nowrap`}
`
export const ProblemCardSource = styled.div`
  ${tw`block text-gray-400 text-sm mt-2 underline`}
`

import tw, { styled } from "twin.macro"

export const Browser = tw.div`mt-8 md:mt-0 border border-gray-200 shadow-xl rounded-tl-lg rounded-tr-lg bg-gray-100 mr-4`
export const Button = styled.div`
  ${tw`rounded-full h-3 w-3 bg-red-400 flex mr-2`}
  &:nth-of-type(2) {
    ${tw`bg-yellow-400`}
  }
  &:nth-of-type(3) {
    ${tw`bg-green-400`}
  }
`

export const Bar = tw.div`flex-auto px-2 py-1 ml-3 rounded-md bg-white text-xs text-gray-400`
export const Top = tw.div`py-2 px-2 bg-gray-200 rounded-tl-md rounded-tr-md flex items-center`
export const Content = styled.div`
  ${tw`bg-white flex w-full flex-col sm:flex-row`}

  &>div {
    ${tw`w-full sm:w-1/2 px-2 h-52 sm:h-72 flex items-center justify-center`}
    &:nth-of-type(2) {
      ${tw`bg-primary`}
    }
  }
`
